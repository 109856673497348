import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import ProjectUserCard from '../ProjectUserCard/Loadable'

import ManageUserBoxStyled from './styledComponent'

import MESSAGE from './message'

class ManageUserBox extends PureComponent {
  getMessage(property) {
    const msg = MESSAGE[this.props.language]
    return msg[property]
  }

  getTableTitle() {
    return (
      <div className="table-header-container">
        <div className="title-column-container">{this.getMessage('title')}</div>
        <div className="collaborator-column-container">{this.getMessage('collaborator')}</div>
      </div>
    )
  }

  getTableBody() {
    const listAdmin = (
      <ProjectUserCard
        users={this.props.listAdmin}
        listManager={this.props.listManager}
        projectName={this.getMessage('administrator')}
        onUpdateUser={this.props.onUpdateUser}
        onRemoveUser={this.props.onRemoveUser}
        language={this.props.language}
        currentUser={this.props.currentUser}
        isAdminMode
      />
    )
    const listProject = this.props.listProject.map((data) => {
      return (
        <ProjectUserCard
          key={data.project_id}
          listManager={this.props.listManager}
          managers={data.managers}
          users={data.users}
          projectID={data.project_id}
          projectName={data.name}
          screenshot={data.image}
          listProject={this.props.listProject}
          onUpdateUser={this.props.onUpdateUser}
          onRemoveUser={this.props.onRemoveUser}
          language={this.props.language}
          currentUser={this.props.currentUser}
        />
      )
    })
    return (
      <div className="table-body-container">
        {this.props.currentUser.admin ? listAdmin : null}
        {listProject}
      </div>
    )
  }

  render() {
    return (
      <ManageUserBoxStyled>
        {this.getTableTitle()}
        {this.getTableBody()}
      </ManageUserBoxStyled>
    )
  }
}

ManageUserBox.defaultProps = {
  language: 'EN',
  currentUser: {
    admin: false,
    manager: false
  }
}

ManageUserBox.propTypes = {
  currentUser: PropTypes.shape({
    admin: PropTypes.bool,
    manager: PropTypes.bool
  }).isRequired,
  language: PropTypes.string,
  listAdmin: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      email: PropTypes.string.isRequired,
      active: PropTypes.bool.isRequired
    })
  ).isRequired,
  listManager: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      email: PropTypes.string.isRequired,
      active: PropTypes.bool.isRequired,
      projects: PropTypes.array.isRequired
    })
  ).isRequired,
  listProject: PropTypes.arrayOf(
    PropTypes.shape({
      project_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      managers: PropTypes.array.isRequired,
      users: PropTypes.array.isRequired,
      image: PropTypes.string
    })
  ),
  onUpdateUser: PropTypes.func.isRequired,
  onRemoveUser: PropTypes.func.isRequired
}

export default ManageUserBox
