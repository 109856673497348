import styled from 'styled-components'

export default styled.div`
  width: 100%;
  .table-header-container {
    position: relative;
    width: 100%;
    display: flex;
    padding: 0 25px 18px 25px;
    font-family: 'Prompt-Bold', sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.5;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 20px;
    .title-column-container {
      padding-left: 80px;
      width: 385px;
    }
    .collaborator-column-container {
      width: 210px;
      text-align: center;
    }
    &:after {
      content: '';
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: 0px;
      left: 0px;
      background: rgba(255, 255, 255, 0.5);
    }
  }
  .table-body-container {
    padding: 0 25px;
  }
`
