const MESSAGE = {
  EN: {
    title: 'TITLE',
    collaborator: 'COLLABORATOR',
    administrator: 'Administrator'
  },
  TH: {
    title: 'หัวข้อ',
    collaborator: 'ผู้ร่วมขบวนการ',
    administrator: 'แอดมิน'
  }
}

export default MESSAGE
